<template>
  <v-dialog
    :fullscreen="$vuetify.breakpoint.mobile"
    width="600"
    scrollable
    v-model="dialog"
    >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        style="background-color: white"
        class="fill-width rounded-lg"
        :class="grow ? 'py-3' : ''"
        :icon="!postType || icon"
        :outlined="!!postType"
        :tile="tile"
        :depressed="!!postType"
        :x-small="xSmall"
        :small="small"
        :color="color"
        v-bind="attrs"
        v-on="on"
        >
        <template
          v-if="postType"
          >
          {{ $t('profiles.actions.' + postType) }}
        </template>
        <template
          v-else
          >
          <v-icon>
            mdi-plus-box
          </v-icon>
        </template>
      </v-btn>
    </template>

    <v-card
      v-if="dialog"
      style="display: flex; flex-direction: column"
      >
      <v-dialog
        v-model="crop"
        width="600"
        scrollable
        :fullscreen="$vuetify.breakpoint.mobile"
        >
        <crop
          v-if="picture"
          :image="picture"
          @crop="setImage"
          @close="crop = false"
          ></crop>
      </v-dialog>
      <v-card-title
        class="py-2"
        >
        <v-btn
          v-if="tabs"
          icon
          small
          class="mr-2 ml-n4"
          @click="tabs = (tabs - 1)"
          >
          <v-icon>
            mdi-arrow-left
          </v-icon>
        </v-btn>

        Nueva publicación

        <v-spacer></v-spacer>

        <v-btn
          icon
          @click="dialog = false"
          class="mr-n4"
          >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text
        class="px-0"
        ref="text"
        >
        <v-form
          class="d-flex flex-column"
          v-model="valid"
          ref="form"
          lazy-validation
          >
          <v-tabs-items v-model="tabs">
            <v-tab-item
              :value="0"
              class="pt-3 px-3"
              >
              <v-autocomplete
                v-if="!postType"
                outlined
                flat
                :label="$t('fields.postType')"
                class="rounded-0"
                :items="postTypes"
                v-model="post.postType"
                >
                <template v-slot:selection="data">
                  <v-list-item-content>
                    <v-list-item-title v-html="$t('posts.types.' + data.item.text)"></v-list-item-title>
                  </v-list-item-content>
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title v-html="$t('posts.types.' + data.item.text)"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>

              <v-text-field
                class="rounded-0"
                label="Tipo de publicación"
                v-else
                :value="$t('posts.types.' + postType)"
                readonly
                outlined
                flat
                ></v-text-field>

              <v-text-field
                class="rounded-0"
                label="Tienda"
                v-if="profile.id"
                :value="profile.name"
                readonly
                outlined
                flat
                ></v-text-field>

              <v-text-field
                class="rounded-0"
                label="Producto"
                v-if="product.id"
                :value="product.name"
                readonly
                outlined
                flat
                ></v-text-field>

              <at-ta 
                :members="members"
                name-key="slug"
                >
                <template slot="item" slot-scope="s">
                  <span v-text="s.item.name"></span>
                </template>

                <v-textarea
                  rows="3"
                  outlined
                  flat
                  :label="$t('fields.body')"
                  class="rounded-0"
                  v-model="post.body"
                  :rules="[ v => !!v || 'El campo es obligatorio']"></v-textarea>
              </at-ta>
            </v-tab-item>

            <v-tab-item
              :value="1"
              class="pt-3"
              >
              <input 
                type="file"
                @change="onFileChange"
                ref="file" 
                accept="image/*"
                style="display: none" 
                ></input>
              <v-row
                v-if="pictures.length"
                id="images-container"
                >
                <v-col
                  cols="12"
                  class="py-0 px-0"
                  order="1"
                  >
                  <v-card
                    outlined
                    tile
                    >
                    <v-img 
                      class="rounded-t-lg text-right elevation-0"
                      :class="selected.filter"
                      ref="image"
                      :id="selected.id"
                      width="100%"
                      :height="cardHeight"
                      :src="selected.src"
                      >
                      <v-btn
                        class="mt-2 mx-6"
                        @click="removeImage"
                        fab
                        depressed
                        x-small
                        color="error"
                        >
                        <v-icon
                          >
                          mdi-delete
                        </v-icon>
                      </v-btn>
                    </v-img>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  class="pt-0 mt-n1"
                  order="2"
                  >
                  <v-card
                    outlined
                    class="rounded-t-0"
                    >
                    <v-card-text>
                      <div
                        style="cursor: pointer"
                        class="text-subtitle-2 d-flex justify-space-between secondary--text"
                        @click="showFilters = !showFilters"
                        >
                        Aplicar filtros
                        <v-icon
                          color="secondary"
                          >{{ showFilters ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                      </div>

                      <v-slide-group
                        v-show="showFilters"
                        v-model="selected.filter"
                        mandatory
                        >
                        <v-slide-item
                          v-for="filter in filters"
                          v-slot="{ active, toggle }"
                          :value="filter.value"
                          >
                          <div>
                            <v-card
                              tile
                              outlined
                              class="my-2"
                              height="70"
                              width="70"
                              @click="toggle"
                              >
                              <v-img
                                :class="filter.value"
                                :id="selected.id"
                                :src="selected.src"
                                ></v-img>
                            </v-card>
                            <div
                              class="caption text-center"
                              >
                              {{ filter.name }}
                            </div>
                          </div>
                        </v-slide-item>
                      </v-slide-group>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col
                  cols="12"
                  class="py-0 fill-width"
                  order="0"
                  >
                  <v-card
                    flat
                    tile
                    width="100%"
                    >
                    <div
                      class="d-flex flex-row fill-width pt-2 pb-2 px-2"
                      style="gap: 8px; overflow-x: auto"
                      >
                      <v-card
                        tile
                        outlined
                        @click.stop="$refs.file.click()"
                        style="flex: none"
                        class="d-flex align-center justify-center"
                        height="70"
                        width="70"
                        >
                        <v-icon
                          color="secondary"
                          size="50"
                          >
                          mdi-plus
                        </v-icon>
                      </v-card>

                      <template
                        v-for="picture in pictures"
                        >
                        <v-card
                          tile
                          outlined
                          height="70"
                          width="70"
                          :key="picture.id"
                          @click="selected = picture"
                          >
                          <v-img
                            :id="picture.id"
                            :src="picture.src"
                            :class="picture.filter"
                            ></v-img>
                        </v-card>
                      </template>
                    </div>
                  </v-card>
                </v-col>
              </v-row>

              <v-row
                v-else
                >
                <v-col
                  cols="12"
                  class="pt-0 px-0"
                  >
                  <v-card
                    class="fill-width"
                    ref="image"
                    outlined
                    tile
                    :height="cardHeight"
                    :key="cardHeight"
                    @click.stop="$refs.file.click()"
                    >
                    <v-card-text
                      class="fill-width fill-height d-flex flex-column justify-center align-center primary--text"
                      >
                      <v-icon
                        size="75"
                        class="mb-3"
                        color="secondary"
                        >
                        mdi-image-plus
                      </v-icon>
                      <div>
                        Tomar una captura
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <template
                v-if="isNative"
                >
                <div
                  class="pa-3 caption d-flex justify-space-between align-center secondary--text font-weight-medium"
                  @click="$refs.manualFile.click()"
                  style="cursor: pointer"
                  >
                  <v-divider
                    class="grow"
                    ></v-divider>
                  <div
                    class="px-2"
                    >
                    O HAZ CLICK AQUÍ PARA BUSCAR UN ARCHIVO
                  </div>
                  <v-divider
                    class="grow"
                    ></v-divider>
                </div>

                <input 
                  type="file"
                  @change="onFileChange"
                  ref="manualFile" 
                  accept="image/*"
                  style="display: none" 
                  ></input>
              </template>
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="fill-width"
        >
        <v-btn
          :loading="loading"
          :disabled="loading"
          block
          class="rounded-lg"
          color="primary"
          @click="next"
          >
          {{ tabs ? 'Crear publicación' : 'Continuar' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style lang="sass" scoped>
#images-container
  .v-btn
    opacity: 0.8
    $btn-hover-opacity: 1 !default      
</style>

<script>
import AtTa from 'vue-at/dist/vue-at-textarea'

import { mapGetters } from 'vuex'
import { CreatePost } from '@/graphql/mutations/posts'
import { Filters } from '@/utils/filters'
import { BaseProfiles } from '@/graphql/queries/profiles'

const Crop = () => import('@/components/media/Crop')

/*
Hi! I'm very sad you stumbled across this piece of sh- code
This file will need a lot of refactor, since due date is tomorrow
And I do not have the time needed to split everything into separated files
I wish you very luck, specially if i'm speaking to myself.
loool Fell you bro.
We might want to start adding comments to our code thinking about our future selfs
 */

export default {
  name: 'CreatePost',
  data: () => ({
    tabs: 0,
    dialog: false,
    crop: false,
    valid: true,
    showFilters: false,
    cardHeight: 300,
    selected: null,
    loading: false,
    picture: null,
    filters: Filters,
    pictures: [],
    members: [],
    postTypes: [
      {
        text: 'standard',
        value: 'standard',
      },
      {
        text: 'looking_for_it',
        value: 'looking_for_it',
      },
    ],
    post: {
      body: null,
      postType: 'standard',
      productId: null,
      storeId: null,
      picturesAttributes: []
    }
  }),

  props: {
    tile: {
      required: false,
      type: Boolean,
      default: false
    },
    color: {
      required: false,
      type: String,
      default: null
    },
    xSmall: {
      required: false,
      type: Boolean,
      default: false
    },
    icon: {
      required: false,
      type: Boolean,
      default: false
    },
    small: {
      required: false,
      type: Boolean,
      default: false
    },
    postType: {
      required: false,
      type: String,
      default: null
    },
    profile: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    },
    grow: {
      required: false,
      type: Boolean,
      default: false
    },
    product: {
      required: false,
      type: Object,
      default: () => {
        return {}
      }
    }
  },

  watch: {
    showFilters (val) {
      this.$nextTick( () => {
        var el = this.$refs.text

        if (val) {
          el.scrollTop = el.scrollHeight
        } else {
          el.scrollTop = 0
        }
      })
    },

    tabs (val) {
      if (val > 0) {
        setTimeout( () => {
          this.cardHeight = this.$refs.image.$el.clientWidth
        }, 100)
      }
    },

    selected: {
      handler (val) {
        this.post.picturesAttributes[val.id].filter = val.filter
      },

      deep: true
    }
  },

  computed: {
    ...mapGetters(['isNative', 'url'])
  },

  created () {
    if (this.postType) this.post.postType = this.postType
    this.fetchProfiles()
    this.post.storeId = this.profile.profilableId
    this.post.productId = this.product.id
  },

  methods: {
    onFileChange(e) {
      var file = e.target.files[0] || e.dataTransfer.files[0]
      this.picture = file
      this.crop = true
    },

    setImage (data) {
      var image = data.image
      var filter = data.filter
      var arr = image.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
      while(n--){
        u8arr[n] = bstr.charCodeAt(n);
      }

      var file = new File([u8arr], this.picture.name, {type:mime});

      var newImg = { 
        image: file,
        filter: filter
      }

      this.post.picturesAttributes.push(newImg)

      this.picture = null
      this.crop = false
      this.selected = { 
        id: this.post.picturesAttributes.indexOf(newImg), 
        src: image, 
        filter: filter
      }

      this.pictures.push(this.selected)
    },

    controlFilter (filter) {
      let selectedImage = document.getElementById(this.selected.id)
      selectedImage.style.cssText += ` filter: brightness(${filter.brightness}%) saturate(${filter.saturation}%) contrast(${filter.contrast}%)  `
    },

    removeImage () {
      var idx = this.pictures.indexOf(this.selected)
      this.pictures.splice(idx, 1)
      this.post.picturesAttributes.splice(idx, 1)

      if (this.pictures.length) {
        this.selected = this.pictures[0]
      }
    },

    next () {
      if (this.tabs) {
        this.createPost ()
      } else {
        this.tabs += 1
      }
    },

    createPost () {
      if (this.valid = this.$refs.form.validate()) {
        this.loading = true
        this.$apollo.mutate({
          mutation: CreatePost,
          variables: {
            input: {
              attributes: {
                ...this.post
              }
            }
          }
        }).then ( res => {
          this.$router.go()
          this.dialog = false
          this.loading = false
        }).catch ( err => {
          this.loading = false
        })
      } else {
        this.tabs = 0
      }
    },

    fetchProfiles () {
      this.$apollo.query({
        query: BaseProfiles,
      }).then ( res => {
        this.members = res.data.profiles
      })
    }
  },

  components: { Crop, AtTa }
}
</script>
